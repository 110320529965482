/* Boolean detecting if the odometer is loaded into view via scroll position detection */
let isOdometerLoaded = false;

/* Storing contact us quick answers data */
let qaData;

/* Storing value from name check cookie to variable */
const namecheckStatus = window.Cookies.get("ResultCheck");

/* Variables assigned for tooltip modal functionality */
const infobox = $(".infobox");
const infoboxBtn = $(".infobox-btn");
const closeBtn = $(".infobox__close");
let resizeTimeout;

/* Functionality detecting the user agent of device to detect is device is a mobile device */
const isMobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function () {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function () {
		return (
			isMobile.Android() ||
			isMobile.BlackBerry() ||
			isMobile.iOS() ||
			isMobile.Opera() ||
			isMobile.Windows()
		);
	},
};

const packages = {
	digital: {
		namecheck: "/name-check/digital-package/",
		checkout: "/buy/digital-package/",
	},
	privacy: {
		namecheck: "/name-check/privacy-package/",
		checkout: "/buy/privacy-package/",
	},
	professional: {
		namecheck: "/name-check/professional-package/",
		checkout: "/buy/professional-package/",
	},
	prestige: {
		namecheck: "/name-check/prestige-package/",
		checkout: "/buy/prestige-package/",
	},
	"all-inclusive": {
		namecheck: "/name-check/all-inclusive-package/",
		checkout: "/buy/all-inclusive-package/",
	},
	"non-residents": {
		namecheck: "/name-check/non-residents-package/",
		checkout: "/buy/non-residents-package/",
	},
	llp: {
		namecheck: "/name-check/llp-package/",
		checkout: "/buy/limited-liability-partnership/",
	},
	"limited-by-guarantee": {
		namecheck: "/name-check/limited-by-guarantee-package/",
		checkout: "/buy/limited-by-guarantee/",
	},
	eseller: {
		namecheck: "/name-check/eseller-package/",
		checkout: "/buy/eseller-package/",
	},
	plc: {
		namecheck: "/name-check/plc-package/",
		checkout: "/buy/plc-package/",
	},
	"reserve-company-name": {
		namecheck: "/name-check/reserve-company-name-package/",
		checkout: "/buy/reserve-company-name/",
	},
	"non-residents--adil-maf": {
		namecheck: "/name-check/non-residents-adil-maf/",
		checkout: "/buy/non-residents-package-adil-maf/",
	},
	"non-residents--adil-maf-pps": {
		namecheck: "/name-check/non-residents-adil-maf-pps/",
		checkout: "/buy/non-residents-package-adil-maf-pps/",
	},
	"professional--adil-maf": {
		namecheck: "/name-check/professional-package-adil-maf/",
		checkout: "/buy/professional-package-adil-maf/",
	},
	"professional--adil-maf-pps": {
		namecheck: "/name-check/professional-package-adil-maf-pps/",
		checkout: "/buy/professional-package-adil-maf-pps/",
	},
	"non-residents--arthur-diniz": {
		namecheck: "/name-check/non-residents-arthur-diniz/",
		checkout: "/buy/non-residents-package-arthur-diniz/",
	},
	"non-residents--netaawy": {
		namecheck: "/name-check/non-residents-netaawy/",
		checkout: "/buy/non-residents-package-netaawy/",
	},
	"prestige--netaawy": {
		namecheck: "/name-check/prestige-package-netaawy/",
		checkout: "/buy/prestige-package-netaawy/",
	},
	"non-residents--mutual-dropshipping": {
		namecheck: "/name-check/non-residents-mutual-dropshipping/",
		checkout: "/buy/non-residents-package-mutual-dropshipping/",
	},
	"prestige--mutual-dropshipping": {
		namecheck: "/name-check/prestige-package-mutual-dropshipping/",
		checkout: "/buy/prestige-package-mutual-dropshipping/",
	},
	"prestige--dropshippingit": {
		namecheck: "/name-check/prestige-package-dropshippingit/",
		checkout: "/buy/prestige-package-dropshippingit/",
	},
	"non-residents--ahmed-al-azaery": {
		namecheck: "/name-check/non-residents-ahmed-al-azaery/",
		checkout: "/buy/non-residents-package-ahmed-al-azaery/",
	},
	"prestige--ahmed-al-azaery": {
		namecheck: "/name-check/prestige-package-ahmed-al-azaery/",
		checkout: "/buy/prestige-package-ahmed-al-azaery/",
	},
	"prestige--marcio-da-amazon": {
		namecheck: "/name-check/prestige-package-marcio-da-amazon/",
		checkout: "/buy/prestige-package-marcio-da-amazon/",
	},
	"prestige--jonny-bradley": {
		namecheck: "/name-check/prestige-package-jonny-bradley/",
		checkout: "/buy/prestige-package-jonny-bradley/",
	},
	"prestige--oye": {
		namecheck: "/name-check/prestige-package-oye/",
		checkout: "/buy/prestige-package-oye/",
	},
};

/* Function triggers on load */
$(function () {
	logoController();
	burgerNavController();
	navigationController();
	window.setTimeout(showCallToAction, 6000);
	changeNamecheckPlaceholder();
	impactIdentify();
	impactClickId();
	infoboxController();
	meetTeamInfobox();
	selectOrderStep();
	namecheckBanks();
	namecheckFormRouting();
	packageRouting();

	if (isPackageTabPage) {
		showActiveTab();
		showCompanyName();
	}

	if (
		isCurrentPage("/serviced-offices/") ||
		isCurrentPage("/meeting-rooms/") ||
		isCurrentPage("/bizik-answering/")
	) {
		checkFormSubmitted();
	}

	if (isCurrentPage("/in-the-press/")) {
		initiateLogoCarousel();
	}

	if (isCurrentPage("/helpful-videos/")) {
		window.MicroModal.init();
	}

	if (isCurrentPage("/contact-us/")) {
		$.ajax({
			url: "https://s3-eu-west-1.amazonaws.com/1stformations/json/FAQ.json",
			dataType: "jsonp",
			jsonpCallback: "skipking541934132",
			success: function (json) {
				qaData = json;
				changeFAQContents(json[0].qa);
			},
		});
	}
});

/* Function triggers on window resize */
$(window).resize(function () {
	navigationController();
	clearTimeout(resizeTimeout);
	resizeTimeout = function () {
		setTimeout(infoboxController, 500);
		setTimeout(meetTeamInfobox, 500);
	};
});

/* Function triggers on window orientation change */
$(window).on("orientationchange", function (event) {
	navigationController();
});

/* Fuction triggers on scroll listener */
$(window).scroll(function () {
	/* Function to show or hide the call to action banner via cooking storage */
	const ctaCookie = window.Cookies && window.Cookies.get("ctahide");
	if (
		window.location.pathname === "/" ||
		window.location.pathname === "/kieran-wip/"
	) {
		if (ctaCookie === undefined && $(".hero-cta").is(":visible")) {
			if ($(window).scrollTop() > 240) {
				$(".hero-cta").addClass("hero-cta--fixed");
			} else {
				$(".hero-cta").removeClass("hero-cta--fixed");
			}
		}

		/* Function to trigger odometer animation when scrolled into view */
		if (!isOdometerLoaded && isScrolledIntoView($("#odometer"))) {
			loadTotalTrustpilotReviews();
		}
	}

	if (window.location.pathname === "/wonder-foundation/") {
		const wScroll = $(this).scrollTop();
		const statItem = $(".wonder-foundation-stats__item");

		if (wScroll > statItem.offset().top - $(window).height() / 1.2) {
			statItem.each(function (i) {
				setTimeout(
					() => {
						statItem.eq(i).addClass("fadeIn");
					},
					300 * (i + 1),
				);
			});
		}
	}

	if ($("#scroll-top").length > 0) {
		if ($(window).scrollTop() > 800) {
			$("#scroll-top").addClass("back-to-top--visible");
		} else {
			$("#scroll-top").removeClass("back-to-top--visible");
		}
	}
});

/* Function to detect which version of the logo to use (light or dark) depending on home page or not */
function logoController() {
	const isHome =
		window.location.pathname === "/" ||
		window.location.pathname === "/kieran-wip/";
	if (isHome) {
		$(".header__logo img").attr(
			"src",
			"https://1stformations.s3.eu-west-1.amazonaws.com/assets/live/images/1st-logo-2022-light.png",
		);
	} else {
		$(".header__logo img").attr(
			"src",
			"https://s3-eu-west-1.amazonaws.com/1stformations/assets/live/images/1st-formations-logo.png",
		);
	}
}

/* Function to show the call to action if not disabled by cookie close */
function showCallToAction() {
	const ctaCookie = window.Cookies.get("ctahide");

	if (ctaCookie === undefined) {
		$("#sticky-cta").slideDown();
		$(".home-hero__main").addClass("home-hero__main--ctafixed");
	}
}

/* Function to trigger the responsive navigation depending on device */
function navigationController() {
	const windowWidth = $(window).width();

	if (windowWidth <= 1025) {
		mobileNavigation();
	} else {
		desktopNavigation();
	}
}

/* Function to trigger the mobile burger navigation to open close toggle */
function burgerNavController() {
	$(document).mouseup(function (e) {
		const burger = $(".header__burger");
		const navigation = $(".navigation__nav");

		if (
			!burger.is(e.target) &&
			burger.has(e.target).length === 0 &&
			!navigation.is(e.target) &&
			navigation.has(e.target).length === 0
		) {
			hideLastActiveDropDown();
			$(".navigation__nav").removeClass("open");
			$(".navigation__nav").addClass("closed");

			$(".header").removeClass("open");
			$(".header").addClass("closed");
		}
	});

	$(".header__burger").click(function () {
		$(".navigation__nav").toggleClass("open");
		$(".navigation__nav").toggleClass("closed");
		$(".header").toggleClass("open");
		$(".header").toggleClass("closed");
	});
}

function mobileNavigation() {
	clearListeners();

	$(".navigation__item--dropdown").on("click", function () {
		if (!$(this).children("div").hasClass("navigation__subnav--inactive")) {
			hideLastActiveDropDown();
		}
		$(this).children("div").toggleClass("navigation__subnav--inactive");
		$(this).children("div").toggleClass("navigation__subnav--active");
	});
}

function desktopNavigation() {
	clearListeners();

	$(".navigation__item--dropdown").on("mouseenter", function () {
		$(this).children("div").removeClass("navigation__subnav--inactive");
		$(this).children("div").addClass("navigation__subnav--active");
		$(this).children("a").addClass("active");
	});

	$(".navigation__item--dropdown").on("mouseleave", function () {
		$(this).children("div").addClass("navigation__subnav--inactive");
		$(this).children("div").removeClass("navigation__subnav--active");
		$(this).children("a").removeClass("active");
	});
}

function clearListeners() {
	$("navigation__item--dropdown").off();
}

function hideLastActiveDropDown() {
	const previousActiveDropdown = $(".site-nav__body");
	const previousDropDownBody = previousActiveDropdown
		.children("navigation__item--dropdown")
		.children("div.site-nav__menu");

	previousDropDownBody.removeClass("navigation__subnav--active");
	previousDropDownBody.addClass("navigation__subnav--inactive");
}

/* Returns the viewport width for use on tooltip modal */
function getViewportWidth() {
	return window.innerWidth || document.documentElement.clientWidth;
}

/* Function determines whether to render a hover tooltip or a click modal dependant on device viewport width */
function infoboxController() {
	const viewportWidth = getViewportWidth();
	infoboxBtn.off();
	if (viewportWidth < 980) {
		infoboxBtn.click(function () {
			$(this).next().addClass("infobox--active");
		});
		infobox.click(function () {
			$(this).removeClass("infobox--active");
		});
		$(".infobox__content").click(function (e) {
			e.stopPropagation();
		});
	} else {
		infoboxBtn.mouseenter(function () {
			closeBtn.before("<span class='infobox__triangle'></span>");
			$(this).next().addClass("infobox--active");
		});
		infoboxBtn.mouseleave(function () {
			$(this).next().removeClass("infobox--active");
			$(this).next().find(".infobox__triangle").remove();
		});
	}
}

/* Function determines whether to render a hover tooltip or a click modal dependant on device viewport width */
function meetTeamInfobox() {
	const infoboxBtn = $(".meet-team__circle");
	const viewportWidth = getViewportWidth();
	infoboxBtn.off();
	if (viewportWidth < 980) {
		infoboxBtn.click(function () {
			$(this).parent().next().addClass("infobox--active");
		});
		infobox.click(function () {
			$(this).removeClass("infobox--active");
		});
		$(".infobox__content").click(function (e) {
			e.stopPropagation();
		});
	} else {
		infoboxBtn.mouseenter(function () {
			closeBtn.before("<span class='infobox__triangle'></span>");
			$(this).parent().next().addClass("infobox--active");
		});
		infoboxBtn.mouseleave(function () {
			$(this).parent().next().removeClass("infobox--active");
			$(this).parent().next().find(".infobox__triangle").remove();
		});
	}
}

/* Function to trigger impact identify api to send the customer's email if they are logged in or blank if logged out */
function impactIdentify() {
	const customerEmail =
		$("#customer-email").val() || "test@1stformations.co.uk";
	if (customerEmail !== "") {
		window.ire("identify", {
			customerId: window.sha1(customerEmail),
			customerEmail: window.sha1(customerEmail),
		});
	} else {
		window.ire("identify", { customerId: "", customerEmail: "" });
	}
}

/* Function to trigger impact click id api to pass the clickid token at checkout */
function impactClickId() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const clickId = urlParams.get("irclickid");

	if (clickId !== null) {
		window.Cookies.set("clickid", clickId, { expires: 30 });
	}
}

/* Function to check company name against eFiling ejax-name-check api */
function checkName(name) {
	$.get(
		"https://www.1stformations.co.uk/ejax-name-check",
		{
			gle: "name-check",
			name: name,
		},
		function (data) {
			data = JSON.parse(data);
			const status = data.NameStatus;
			const requestedName = data.NameRequested;
			const sensitiveWords = data.SensitiveWords;
			const sensitiveWordsArr = [];
			const similarName = data.MatchName;
			const namecheck = $(".hero__namecheck");
			const results = $(".namecheck-results");
			let html = "";

			$(".namecheck-results__results").removeClass(
				"namecheck-results__results--green namecheck-results__results--amber namecheck-results__results--red",
			);
			namecheck.hide();
			results.empty();
			results.show(1000);

			if (status === "GREEN") {
				html +=
					'<div class="namecheck-results__results namecheck-results__results--green">';
				html += `<div class="namecheck-results__icon"><i class="fa-duotone fa-circle-check" style="--fa-primary-color: white; --fa-secondary-color: #62a43f; --fa-secondary-opacity: 1.0;"></i></div>`;
				html += `<h1>${requestedName}</h1>`;
				html += `<p>Congratulations! This company name is available.</p>`;
				html += `<div class="namecheck-results__buttons"><a href="/compare-packages/" class="button button--success">Choose a Package</a>`;
				html += `<a id="reset-namecheck" class="button button--text">Or search again</a></div></div>`;
				results.append(html);
				setCookie("ResultCheck", "green");
				checkoutRouting();
			} else if (status === "AMBER") {
				html +=
					'<div class="namecheck-results__results namecheck-results__results--amber">';
				for (let i = 0; i < sensitiveWords.length; i++)
					sensitiveWordsArr.push(sensitiveWords[i].Word);
				const sensitiveWordsList = sensitiveWordsArr.join(", ");

				html += `<div class="namecheck-results__icon"><i class="fa-duotone fa-circle-check" style="--fa-primary-color: white; --fa-secondary-color: #62a43f; --fa-secondary-opacity: 1.0;"></i></div>`;
				html += `<h1>${requestedName}</h1>`;
				html += `<p>Please note: The word(s) <strong>${sensitiveWordsList}</strong> is deemed sensitive. You may need to supply additional information to use it.</p>`;
				html += `<div class="namecheck-results__buttons"><a href="/compare-packages/" class="button button--success">Choose a Package</a>`;
				html += `<a id="reset-namecheck" class="button button--text">Or search again</a></div></div>`;
				results.append(html);
				setCookie("ResultCheck", "green");
				checkoutRouting();
			} else if (status === "RED") {
				html +=
					'<div class="namecheck-results__results namecheck-results__results--red">';
				html += `<div class="namecheck-results__icon"><i class="fa-duotone fa-circle-xmark" style="--fa-primary-color: white; --fa-secondary-color: red; --fa-secondary-opacity: 1.0;"></i></div>`;
				html += `<h1>${requestedName}</h1>`;
				html += `<p>Sorry, this company name is unavailable.</p>`;
				html += `<div class="namecheck-results__buttons"><a id="reset-namecheck" class="button button--text">Search again</a></div></div>`;
				results.append(html);
				setCookie("ResultCheck", "red");
				namecheckRouting();
			} else {
				html +=
					'<div class="namecheck-results__results namecheck-results__results--red">';
				html += `<div class="namecheck-results__icon"><i class="fa-duotone fa-circle-xmark" style="--fa-primary-color: white; --fa-secondary-color: red; --fa-secondary-opacity: 1.0;"></i></div>`;
				html += `<h1>${requestedName}</h1>`;
				html += `<p>Sorry, this company name is unavailable. Also unavailable is: ${similarName}.</p>`;
				html += `<div class="namecheck-results__buttons"><a id="reset-namecheck" class="button button--text"Search again</a></div></div>`;

				results.append(html);
				setCookie("ResultCheck", "red");
				namecheckRouting();
			}
		},
	);
}

function resetNameCheck() {
	changeNamecheckPlaceholder();
	$(".namecheck-results").empty().hide();
	$(".hero__namecheck").show();
}

/* Function detects if namecheck is successful using cookie storage and checking for green or amber status */
function packageRouting() {
	const namecheckStatus =
		(window.Cookies && window.Cookies.get("ResultCheck")) || null;
	if (namecheckStatus !== null) {
		if ((namecheckStatus === "green") | (namecheckStatus === "amber")) {
			checkoutRouting();
		} else if (namecheckStatus === "red") {
			namecheckRouting();
		} else {
			namecheckRouting();
		}
	}
}

/*
/ Function for checkout routing via buy package buttons 
/ This checks every buy now button with class of `js-routing`
/ and retrieves the package via data-attribute called `data-package`
/ this then replaces the href attribute with a link to the packages'
/ checkout page.
*/
function checkoutRouting() {
	$(".button.js-routing").each((index, element) => {
		const packageName = $(element).data("package");
		$(element).attr("href", packages[packageName].checkout);
	});
}

/*
/ Function for name check routing via buy package buttons 
/ This checks every buy now button with class of `js-routing`
/ and retrieves the package via data-attribute called `data-package`
/ this then replaces the href attribute with a link to the packages'
/ namecheck page.
*/
function namecheckRouting() {
	$(".button.js-routing").each((index, element) => {
		const packageName = $(element).data("package");
		$(element).attr("href", packages[packageName].namecheck);
	});
}

/* Utility function to get url parameter by property name e.g. 'action' */
function getUrlParam(name) {
	const results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(
		window.location.href,
	);
	if (results === null) {
		return null;
	} else {
		return results[1] || 0;
	}
}

/* Utility function to set cookie */
function setCookie(name, value, options = { path: "/" }) {
	window.Cookies.set(name, value, options);
}

/* Utility function to get cookie */
function getCookie(name) {
	const value = "; " + document.cookie;
	const parts = value.split("; " + name + "=");
	if (parts.length === 2) {
		return parts.pop().split(";").shift();
	}
}

/* Utility function to scroll to element */
function scrollTo(element, offset = false, speed = 2000) {
	if (offset) {
		$("html, body").animate(
			{ scrollTop: element.offset().top - 30 },
			speed,
		);
	} else {
		$("html, body").animate({ scrollTop: element.offset().top }, speed);
	}
}

/* Function to set odometer value to total trustpilot reviews. This is static currently. Plan to make it automatic */
function loadTotalTrustpilotReviews() {
	isOdometerLoaded = true;
	window.odometer.innerHTML = 19033;
}

/* Function to change the placeholder text within the company anme check depending on mobile device or not */
function changeNamecheckPlaceholder() {
	const windowWidth = $(window).width();

	if (windowWidth <= 992) {
		$("#company-name").attr("placeholder", "Enter your company name");
	}
}

/* Utility function to detect if element is scrolled into view */
function isScrolledIntoView(elem) {
	const docViewTop = $(window).scrollTop();
	const docViewBottom = docViewTop + $(window).height();

	const elemTop = $(elem).offset().top;
	const elemBottom = elemTop + $(elem).height();

	return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

/* Utility function to detect if the user is logged into eFiling using the meta-ef-urchin-ui property injected by eFiling */
/*
function isLoggedIn() {
    const metauid = document.querySelector("meta[name='ef-urchin-uid']") && document.querySelector("meta[name='ef-urchin-uid']").getAttribute("content");
    return metauid !== null && metauid !== "";
}
*/

/* Function detects if page is a package page tab */
function isPackageTabPage() {
	const pages = [
		"compare-packages",
		"llp",
		"limited-by-guarantee",
		"eseller",
		"plc",
		"non-residents",
	];

	const page = window.location.pathname;

	return pages.includes(page);
}

/* Utilty function to detect if current page is the expected page */
function isCurrentPage(page) {
	return window.location.pathname.indexOf(page) > -1;
}

/* Functions renders the saved company name if completed */
function showCompanyName() {
	const hasCompanyName = window.Cookies.get("ResultCheck") === "green";
	if (hasCompanyName) {
		$(".compare-package-namecheck").css("display", "flex");
	} else {
		$(".compare-package-namecheck").css("display", "none");
	}
}

/* Function sets active class to current tab on compare packges */
function showActiveTab() {
	const page = window.location.pathname;
	$(".compare-package-tabs__tabs li").removeClass("active");
	if (page.indexOf("package/non-residents") > -1)
		$('.compare-package-tabs__tabs li[data-tab="non-residents"]').addClass(
			"active",
		);
	if (page.indexOf("package/eseller") > -1)
		$('.compare-package-tabs__tabs li[data-tab="eseller"]').addClass(
			"active",
		);
	if (page.indexOf("package/llp") > -1)
		$('.compare-package-tabs__tabs li[data-tab="llp"]').addClass("active");
	if (page.indexOf("package/limited-by-guarantee") > -1)
		$('.compare-package-tabs__tabs li[data-tab="guarantee"]').addClass(
			"active",
		);
	if (page.indexOf("package/plc") > -1)
		$('.compare-package-tabs__tabs li[data-tab="plc"]').addClass("active");
	if (page === "/compare-packages/")
		$(
			'.compare-package-tabs__tabs li[data-tab="limited-company"]',
		).addClass("active");
}

function changeFAQContents(data) {
	$("#faq-questions").empty();
	for (let i = 0; i < data.length; i++) {
		let html = `<div class="quick-answers__faq">`;
		html += `<h4 class="quick-answers__question">${data[i].question}</h4>`;
		html += `<div class="quick-answers__answer">${data[i].answer}</div></div>`;
		$("#faq-questions").append(html);
	}
}

/* Function detects if the form has been submitted and shows thank you message */
function checkFormSubmitted() {
	const urlParams = new URLSearchParams(window.location.search);
	const actionParam = urlParams.get("action");
	if (actionParam && actionParam === "submitted") {
		$("#contact-us-thankyou").show();
		$("html, body").animate(
			{ scrollTop: $("#contact-us-thankyou").offset().top },
			2000,
		);
	} else {
		$("#contact-us-thankyou").hide();
	}
}

function selectOrderStep() {
	const showOrderSteps = hasOrderSteps();
	const page = window.location.pathname;
	let currentStep = 1;

	if (showOrderSteps) {
		if (page.indexOf("/name-check/") > -1) {
			currentStep = 1;
		} else if (
			page.indexOf("/package/") > -1 ||
			page.indexOf("/compare-packages/") > -1
		) {
			currentStep = 2;
		} else if (page.indexOf("/buy/") > -1) {
			currentStep = 3;
		} else if (
			page.indexOf("/formation-page/") > -1 ||
			page.indexOf("/cart/") > -1
		) {
			currentStep = 4;
		} else {
			currentStep = 4;
		}

		$(`.order-steps__step[data-step="${currentStep}"]`).addClass("current");

		[...Array(currentStep - 1 + 1).keys()].map((step) =>
			$(`.order-steps__step[data-step="${step}"]`).addClass("active"),
		);
	}
}

/* Function detects if order steps component is on page */
function hasOrderSteps() {
	return $(".order-steps").length > 0;
}

/* Function detects if page is a namecheck page */
function isNameCheckPage() {
	return window.location.pathname.indexOf("/name-check/") > -1;
}

/* Function routes name check package pages to correct checkout etc. */
function namecheckFormRouting() {
	if (window.location.pathname === "/name-check/") {
		let action = window.location.hash.substring(1);
		if (action === "") action = getUrlParam("action");
		if (action === "namecheckpath") {
			$('a:contains("Continue")').attr(
				"href",
				"/compare-packages/#namecheckpath",
			);
			$('form[action^="/name-check/"]').attr(
				"action",
				"/name-check/#namecheckpath",
			);
		}
		if (action !== "namecheckpath" && action !== null) {
			$('a:contains("Continue")').attr("href", packages[action].checkout);
			$('form[action^="/name-check/"]').attr(
				"action",
				`/name-check/#${action}`,
			);
			$('input[name="action"]').attr("value", action);
		}
	}
}

/* Function hides and shows specific bank selections based on if non-resident package or not */
function namecheckBanks() {
	if (window.location.pathname === "/name-check/") {
		let action = window.location.hash.substring(1);
		if (action === "") action = getUrlParam("action");

		if (action === "non-residents") {
			$(".bank-ads.bank-ads--default").hide();
		} else {
			$(".bank-ads.bank-ads--non-residents").hide();
		}
	}
}

/* Function to check entered company name value */
function checkInput() {
	const name = $("#company_name").val();
	if ($.trim(name) === "") return false;
	return true;
}

/* Function to initiate animation on carousel of logos for In The Press */
function initiateLogoCarousel() {
	const scrollers = document.querySelectorAll(".scroller");

	scrollers.forEach((scroller) => {
		const scrollerInner = scroller.querySelector(".scroller__inner");
		const scrollerContent = Array.from(scrollerInner.children);

		scrollerContent.forEach((item) => {
			const duplicatedItem = item.cloneNode(true);
			duplicatedItem.setAttribute("aria-hidden", true);
			scrollerInner.appendChild(duplicatedItem);
		});
	});
}

/* Function to async post form data to endpoint */
async function postData(url = "", data = {}) {
	const response = await fetch(url, {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "same-origin",
		headers: {
			"Content-Type": "application/json",
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(data),
	});
	return response.json();
}

/* Function to format form fields object to JSON */
function formFieldsToJSON(form) {
	const fieldArray = [];
	const formData = new FormData(form);
	for (const field of formData) {
		const values = {
			name: field[0],
			value: field[1],
		};
		fieldArray.push(values);
	}
	return fieldArray;
}

/* Function to build Hubspot Context */
function buildHubspotContext() {
	const hsContext = {};
	hsContext.hutk = getCookie("hubspotutk");
	hsContext.pageUri = window.location.href;
	hsContext.pageName = document.title;
	return hsContext;
}

/* Function to prepare the form submission to Hubspot */
function prepareHubspotFormSubmission(form) {
	const submissionData = {};
	submissionData.submittedAt = Date.now();
	submissionData.fields = formFieldsToJSON(form);
	submissionData.context = buildHubspotContext();
	return submissionData;
}

/* Function to submit contact form to Hubspot */
function submitHubspotForm(hsFormURL, data) {
	postData(hsFormURL, data).then((data) => {
		if (data.inlineMessage) {
			window.location.href = "/bizik-answering/?action=submitted";
		}
	});
}

/* Sticky call to action close button event listener to hide the call to action and remember setting via cookie storage */
$("body").on("click", ".hero-cta__close", function () {
	setCookie("ctahide", true, { path: "/" });
	$(".hero-cta").slideUp();
	$(".hero-cta").removeClass("hero-cta--fixed");
	$(".hero__main").removeClass("hero__main--ctafixed");
});

/* Formation video event listener to take user to top of page when clicked */
$("#video-feature").on("click", function (e) {
	e.preventDefault();
	return $("html, body").animate(
		{ scrollTop: $($.attr(this, "href")).position().top },
		2500,
	);
});

/* Namecheck search button event listener to trigger searching the company name against eFiling api */
$("#namecheck-button").on("click", function (event) {
	event.preventDefault();
	const requestedName = $("#company-name").val();

	$("#company-name").val("");
	$("#company-name").blur();

	if (
		requestedName === "" ||
		requestedName === "Enter your company name..." ||
		requestedName ===
			"Enter your company name to check if it is available" ||
		requestedName === "eg. The CoffeeBean Ltd" ||
		requestedName === undefined
	) {
		return false;
	} else {
		checkName(requestedName);
		return true;
	}
});

/* Namecheck reset button event listener to reset the name check */
$("body").on("click", "#reset-namecheck", function () {
	resetNameCheck();
});

/* FAQ collapsible event listener to collapse or expand the selected FAQ item */
$(".accordion-faqs__faq").on("click", function () {
	const answer = $(this).find(".accordion-faqs__answer");
	if (answer.css("display") === "none") {
		$(".accordion-faqs__answer").slideUp("fast");
		$(".accordion-faqs__question span svg:first-child", $(this)).hide();
		$(".accordion-faqs__question span svg:last-child", $(this)).show();
		answer.slideDown();
	} else {
		answer.slideUp();
		$(".accordion-faqs__question span svg:first-child", $(this)).show();
		$(".accordion-faqs__question span svg:last-child", $(this)).hide();
	}
});

/* Video transcript event listener to load more content from the transcript of a case study video */
$(".video-transcript__readMore").on("click", function () {
	$(this).hide();
	$(".video-transcript__moreText").addClass("show");
});

$(".mobile-package-boxes__more-button").on("click", function () {
	if (isPackageTabPage) {
		$(this).parent().next().toggle("slow");
	}
});

$(".helpful-videos__category").on("click", function () {
	const category = $(this).data("category");
	$(".helpful-videos__category").removeClass("selected");
	$(this).addClass("selected");
	$("html, body").animate(
		{ scrollTop: $("#videos--" + category).offset().top - 30 },
		2000,
	);
});

/* Case study category filter event listen to hide or show based on category of case studies */
$(".case-studies__category").on("click", function () {
	const selectedCategory = $(this).attr("data-industry");
	$(".case-studies__category").removeClass("current");
	$(this).addClass("current");

	if (selectedCategory === "all") {
		$(".case-studies__study").removeClass("hide").addClass("show");
		$(".case-studies-banner").show();
		$(".case-studies-quote").show();
		$(".case-studies-partner-banner").show();
	} else {
		$(".case-studies__study").removeClass("show").addClass("hide");
		$(`.case-studies__study[data-industry="${selectedCategory}"]`)
			.removeClass("hide")
			.addClass("show");
		$(".case-studies-banner").hide();
		$(".case-studies-quote").hide();
		$(".case-studies-partner-banner").hide();
	}
});

/* Function filters case studies by selected industry */
$(".case-studies__categories").on("change", function () {
	const selectedCategory = $(this).find(":selected").val();

	if (selectedCategory === "all") {
		$(".case-studies__study").removeClass("hide").addClass("show");
		$(".case-studies-banner").show();
		$(".case-studies-quote").show();
		$(".case-studies-partner-banner").show();
	} else {
		$(".case-studies__study").removeClass("show").addClass("hide");
		$(`.case-studies__study[data-industry="${selectedCategory}"]`)
			.removeClass("hide")
			.addClass("show");
		$(".case-studies-banner").hide();
		$(".case-studies-quote").hide();
		$(".case-studies-partner-banner").hide();
	}
});

/* Scrolls to the selected category on desktop */
$(".partnerships__categories--desktop .partnerships__category").on(
	"click",
	function () {
		const category = $(this).data("category");
		scrollTo($("#partnerships-category--" + category), true, 2000);
	},
);

/* Scrolls to the selected category on mobile via select field */
$(".partnerships__categories--mobile select").on("change", function () {
	const category = $(this).val();
	$(this).blur();
	scrollTo($("#partnerships-category--" + category), true, 2000);
});

$(".so-contact-button").on("click", function () {
	scrollTo($("#section--serviced-office-contact-us"), false, 2000);
});

$(".mr-explore-rooms").on("click", function () {
	scrollTo($("#section--meeting-rooms-book-room"), true, 2000);
});

$(".mr-contact-button").on("click", function () {
	scrollTo($("#section--meeting-rooms-contact-us"), true, 2000);
});

$(".office-faqs__faq").on("click", function () {
	const answer = $(this).find(".office-faqs__answer");
	if (answer.css("display") === "none") {
		$(".office-faqs__answer").slideUp("fast");
		answer.slideDown();
		answer.prev().find(".office-faqs__icon .fa-angle-right").hide();
		answer.prev().find(".office-faqs__icon .fa-angle-down").show();
	} else {
		answer.slideUp();
		answer.prev().find(".office-faqs__icon .fa-angle-down").hide();
		answer.prev().find(".office-faqs__icon .fa-angle-right").show();
	}
});

$(".meeting-rooms-faqs__faq").on("click", function () {
	const answer = $(this).find(".meeting-rooms-faqs__answer");
	if (answer.css("display") === "none") {
		$(".meeting-rooms-faqs__answer").slideUp("fast");
		answer.slideDown();
		answer.prev().find(".meeting-rooms-faqs__icon .fa-angle-right").hide();
		answer.prev().find(".meeting-rooms-faqs__icon .fa-angle-down").show();
	} else {
		answer.slideUp();
		answer.prev().find(".meeting-rooms-faqs__icon .fa-angle-down").hide();
		answer.prev().find(".meeting-rooms-faqs__icon .fa-angle-right").show();
	}
});

$(".collapsible__header").on("click", function () {
	// Close all other collapsibles
	$(".collapsible__content").slideUp();
	$(".collapsible__icons .fa-angle-down").hide();
	$(".collapsible__icons .fa-angle-right").show();

	const current = $(this).parent();

	if (current.find(".collapsible__content").css("display") === "none") {
		current.find(".collapsible__content").slideDown();
		current.find(".collapsible__icons .fa-angle-right").hide();
		current.find(".collapsible__icons .fa-angle-down").show();
	} else {
		current.find(".collapsible__content").slideUp();
		current.find(".collapsible__icons .fa-angle-down").hide();
		current.find(".collapsible__icons .fa-angle-right").show();
	}
});

$(".quick-answers__category").on("click", function () {
	const isExpanded = $(this)
		.next()
		.hasClass("quick-answers__dropdown--active");

	if (!isExpanded) {
		$(this).next().addClass("quick-answers__dropdown--active");
		$(this).find(".fa-angle-right").hide();
		$(this).find(".fa-angle-down").show();
	} else {
		$(this).next().removeClass("quick-answers__dropdown--active");
		$(this).find(".fa-angle-down").hide();
		$(this).find(".fa-angle-right").show();
	}
});

$("#quick-answers-dropdown li").on("click", function () {
	// Looping through JSON file to find questions and answers relating to heading.
	for (let i = 0; i < qaData.length; i++) {
		if (qaData[i].subject === this.children[0].innerHTML) {
			$(".quick-answers__dropdown").removeClass(
				"quick-answers__dropdown--active",
			);
			$("#quick-answers-dropdown")
				.find('li[class="active"]')
				.removeClass("active");
			$(this).addClass("active");
			changeFAQContents(qaData[i].qa);
			$(".quick-answers__category h4").text(qaData[i].subject);
			break;
		}
	}
});

$("body").on("click", ".quick-answers__question", function () {
	const isExpanded = $(this).next().hasClass("quick-answers__answer--active");

	if (!isExpanded) {
		$(".quick-answers__answer").removeClass(
			"quick-answers__answer--active",
		);
		$(this).next().addClass("quick-answers__answer--active");
	} else {
		$(this).next().removeClass("quick-answers__answer--active");
	}
});

$(".featured-video__video .helpful-videos__thumbnail").on(
	"click touchstart",
	function () {
		// Show modal iframe vimeo video within image
		window.MicroModal.show("featured-video-modal");

		// Change autoplay to be enabled by changing src of iframe on click
		let element = $("iframe#vimeo-iframe-video").attr("src");
		element = element.replace("autoplay=0", "autoplay=1");
		$("iframe#vimeo-iframe-video").attr("src", element);
	},
);

const bizikContactForm = document.querySelector("#bizik-contact-us");

bizikContactForm.addEventListener("submit", function (event) {
	event.preventDefault();
	const baseSubmitURL =
		"https://api.hsforms.com/submissions/v3/integration/submit";
	const portalId = "144459116";
	const formGuid = "8aca939e-19c7-4319-931f-94392d0a602f";
	const submitURL = `${baseSubmitURL}/${portalId}/${formGuid}`;
	const formData = prepareHubspotFormSubmission(bizikContactForm);
	submitHubspotForm(submitURL, formData);
});

$("#scroll-top").on("click", function () {
	$("html, body").animate({ scrollTop: 0 }, 500);
});
